import { TClient, TClientSetting, TContact } from "src/types";
import { RootStore } from "./rootStore";
import {
  flow,
  get,
  keys,
  makeAutoObservable,
  reaction,
  set,
  toJS,
  values,
  when,
} from "mobx";
import { isHydrated, makePersistable } from "mobx-persist-store";
import {
  bountyAddressSetting,
  custodialWalletSetting,
  dxsAccessSetting,
  pkSetting,
  secretSetting,
} from "src/types.enums";

export class ClientStore {
  _secret?: string;

  client?: TClient;
  // appTheme: TAppTheme = "dark";
  bountyBannerClosed: boolean = false;

  _settings: { [type: string]: string } = {};
  _contacts: { [idx: string]: TContact } = {};

  isReady: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      storage: window.localStorage,
      stringify: true,
      name: "ClientStore",
      properties: [
        // "appTheme",
        "client",
        "_contacts",
        "_settings",
        "bountyBannerClosed",
      ],
    });

    reaction(
      () => this.rootStore.userStore.IsLoggedOut,
      (isLoggedOut) => {
        if (isLoggedOut) {
          this.client = undefined;
          this.bountyBannerClosed = true;
          this._settings = {};
          this._contacts = {};
        }
      },
      { fireImmediately: true }
    );
  }

  init = flow(function* (this: ClientStore) {
    yield when(() => isHydrated(this));

    // if (!AppThemes.guard(this.appTheme)) this.appTheme = "dark";

    this.isReady = true;
  });

  get Client() {
    return this.client!;
  }

  get contactIdxs() {
    return keys(this._contacts);
  }

  get contacts() {
    return values(this._contacts);
  }

  get IsCustodial() {
    return Boolean(toJS(get(this._settings, custodialWalletSetting)));
  }

  get BountyAddress(): string {
    return get(this._settings, bountyAddressSetting);
  }

  get IsEthereumAccount() {
    return this.client?.IdentityProvider !== "Identity";
  }

  get Secret() {
    if (!this._secret) throw Error("Invalid state");

    return this._secret!;
  }

  getContact = (idx: string): TContact => get(this._contacts, idx);

  onSettingChanged = ({ Type, Value }: TClientSetting) => {
    if (Type === pkSetting) {
      this.rootStore.walletStore._encoded = Value;
    } else if (Type === secretSetting) {
      this._secret = Value;
    } else {
      set(this._settings, Type, Value);
    }
  };

  onClientChanged = (client: TClient) => {
    this.client = client;
  };

  grantDxsAccess = this.rootStore.singleCall(
    "grantDxsAccess",
    flow(function* ({ connectionStore }: RootStore) {
      yield connectionStore.updateSetting({
        Type: dxsAccessSetting,
        Value: "DXS",
      });
    })
  );

  loadContacts = this.rootStore.singleCall(
    "loadContacts",
    flow(function* ({ clientStore: $this, connectionStore }: RootStore) {
      const contacts: TContact[] = yield connectionStore.listContacts({
        Take: 50,
        Skip: 0,
        Desc: true,
      });

      if (contacts) {
        let inserted = 0;

        for (const row of contacts) {
          set($this._contacts, inserted.toString(), row);
          inserted++;
        }
      }
    })
  );

  addContact = flow(function* (this: ClientStore, contact: TContact) {
    yield this.rootStore.connectionStore.addContact(contact);

    const contacts = toJS(this._contacts);
    const ids = Object.keys(contacts);
    set(this._contacts, "0", contact);

    for (let id of ids) {
      set(this._contacts, (Number.parseInt(id) + 1).toString(), contacts[id]);
    }
  });

  closeBountyBanner = () => (this.bountyBannerClosed = true);
}
