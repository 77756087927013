import { RootStore } from "../../store";
import { sha256, toHexBuffer, toUtf8Buffer } from "dxs-stas-sdk";
import getBrowserFingerprint from "get-browser-fingerprint";
import { decrypt, encrypt, utils } from "micro-aes-gcm";
import { when } from "mobx";
import { walletService } from "../wallet-service";
import { sleep, unwrapError } from "../../utils";
import { TPacket } from "./types";

const getBrowserSecret = () =>
  sha256(toUtf8Buffer(getBrowserFingerprint().toString()));

export const createPacket = async (rootStore: RootStore): Promise<TPacket> => {
  // only for browsers which support stupid storage access API (safari)
  if (!Boolean(document.requestStorageAccess)) return null;

  const {
    userStore: { AccessToken },
    walletStore: { _encoded },
  } = rootStore;

  const secret = getBrowserSecret();
  const message = JSON.stringify({
    accessToken: AccessToken,
    pk: _encoded,
  });

  const encrypted = Buffer.from(
    await encrypt(secret, utils.utf8ToBytes(message))
  );
  const payload = encrypted.toString("hex");
  return payload;
};

export const tryApplyPacket = async (rootStore: RootStore, payload: string) => {
  // only for browsers which support storage access API (safari)
  if (!Boolean(document.requestStorageAccess)) return;

  const {
    makeBlockingCallback,
    userStore: { IsLoggedIn, _setAccessToken },
    walletStore: { setPk },
  } = rootStore;

  if (IsLoggedIn) return;
  if (!payload) return;

  await makeBlockingCallback(async () => {
    try {
      const browserSecret = getBrowserSecret();
      const phraseUtf8Bytes = await decrypt(
        browserSecret,
        toHexBuffer(payload)
      );
      const message = utils.bytesToUtf8(phraseUtf8Bytes);
      const { accessToken, pk } = JSON.parse(message);

      if (await _setAccessToken(accessToken)) {
        try {
          await when(() => rootStore.connectionStore.connected);

          const mnemonic = await walletService.decodeMnemonic(
            rootStore.clientStore._secret!,
            pk
          );

          await setPk(mnemonic);
          await sleep(1000);
        } catch {
          throw new Error("Validate mnemonic");
        }
      }
    } catch (error) {
      console.error(
        "Unable to parse packet #frameService #FIORIN",
        unwrapError(error)
      );
    }
  });
};
