import { RootStore } from "../../store";
import { toJS } from "mobx";
import {
  TAuthorizedMessage,
  TBalancesMessage,
  TToken,
  TUserMessage,
} from "./types";
import { createPacket } from "./manage-packet";

const getBountyBalance = (rootStore: RootStore): TToken => {
  const {
    walletStore: { bountyTokenId, bountyBalance },
  } = rootStore;

  const centsPerUsd = 100;
  const amount = bountyBalance / centsPerUsd; // TODO [Oleg] use scheme in future

  return {
    tokenId: bountyTokenId,
    amount,
    amountUsd: amount,
    currency: "Usdt",
    name: "Bounty",
    order: 100000,
  };
};

const getActiveTokenList = (rootStore: RootStore): TToken[] =>
  rootStore.walletStore.tokensWithBalance.map(
    ({ config, balance, balanceUsd }) => ({
      tokenId: config.scheme.TokenId,
      name: config.scheme.Name,
      amount: balance,
      amountUsd: balanceUsd,
      currency: config.cryptoAsset,
      order: config.order,
    })
  );

const getTokenWithMaxBalance = (rootStore: RootStore): TToken => {
  const {
    walletStore: {
      tokenWithMaxBalance: {
        balanceUsd,
        balance,
        config: {
          cryptoAsset,
          scheme: { TokenId, Name },
        },
      },
    },
  } = rootStore;

  return {
    tokenId: TokenId,
    amount: balance,
    amountUsd: balanceUsd,
    name: Name,
    currency: cryptoAsset,
    order: 0,
  };
};

const buildUserMessage = async (
  rootStore: RootStore
): Promise<TUserMessage> => {
  const {
    userStore: {
      AccessToken,
      User: { email },
    },
    clientStore: { Client },
    walletStore: {
      BsvAccount: { Address },
    },
  } = rootStore;
  const packet = await createPacket(rootStore);

  return {
    email,
    username: Client.Handle,
    bsvAccountAddress: Address,
    provider: Client.IdentityProvider,
    token: toJS(AccessToken),
    packet,
  };
};

export const buildAllBalancesMessage = (
  rootStore: RootStore
): TBalancesMessage => {
  const activeTokens = getActiveTokenList(rootStore);
  const bountyBalance = getBountyBalance(rootStore);
  const tokenWithMaxBalance = getTokenWithMaxBalance(rootStore);
  const pendingBalance = rootStore.historyStore.pendingDeposit?.Amount ?? 0;

  return {
    pendingBalance,
    bountyBalance,
    activeTokens,
    tokenWithMaxBalance,
  };
};

export const buildAuthorizedMessage = async (
  rootStore: RootStore
): Promise<TAuthorizedMessage> => ({
  user: await buildUserMessage(rootStore),
  balances: buildAllBalancesMessage(rootStore),
});
