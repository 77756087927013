import { RootStore, rootStore } from "src/store";
import { envHelper, iFrameMasterHost } from "../config-service";
import { reaction, when } from "mobx";
import { history, routes } from "../routes-service";
import { sleep, unwrapError } from "src/utils";
import { sendTransaction } from "./send-transaction";
import { tryApplyPacket } from "./manage-packet";
import {
  buildAllBalancesMessage,
  buildAuthorizedMessage,
} from "./build-messages";
import { ClientEvent, TClientEvent, WalletEvent } from "./types";

export type RequestId = string | null;
export type RequestPayload = { packet: string | undefined };

export type SelfMessageEvent = MessageEvent<{
  event: TClientEvent;
  payload: RequestPayload;
  requestId: RequestId;
}>;

export const isInFrame = () => window.self !== window.top;

const windowOpen = window.open;

window.open = (
  url?: string | URL,
  target?: string,
  features?: string
): WindowProxy | null => {
  if (!isInFrame()) return windowOpen(url, target, features);

  frameService.openLink(url, target, features);

  return null;
};

// TODO [Oleg] Must be store not service
export class FrameService {
  constructor(private rootStore: RootStore) {}

  close = () => {
    this.sendMessage(null, WalletEvent.Closed, null);
  };

  decline = () => {
    this.sendMessage(null, WalletEvent.Declined, null);
  };

  openMe = (requestId: RequestId) => {
    this.sendMessage(requestId, WalletEvent.OpenMe, null);
  };

  openLink = (url?: string | URL, target?: string, features?: string) => {
    this.sendMessage(null, WalletEvent.OpenLink, { url, target, features });
  };

  withdrawal = (amount: number) => {
    this.sendMessage(null, WalletEvent.Withdrawal, { amount });
  };

  deposit = (amount: number) => {
    this.sendMessage(null, WalletEvent.Deposit, { amount });
  };

  error = (message: string) => {
    this.sendMessage(null, WalletEvent.Error, {
      message,
    });
  };

  private sendAuthorized = async () => {
    const message = await buildAuthorizedMessage(this.rootStore);

    this.sendMessage(null, WalletEvent.Authorized, message);
  };

  public init = async () => {
    window.addEventListener("message", (message) => {
      try {
        this.handleMessage(message);
      } catch (e) {
        console.error("Error in handleMessage #frameService #FIORIN", e);
      }
    });

    this.ensureHasAccess(null, "INIT");
    this.sendMessage(null, WalletEvent.Ready, null);

    reaction(
      () => this.rootStore.connectionStore.connected,
      async (connected) => {
        if (!connected) return;

        if (!this.rootStore.walletStore.hasWallet) {
          await when(() => this.rootStore.walletStore.hasWallet);
        }

        this.rootStore._lock++;

        if (!this.rootStore.walletStore.hasBsvAccount) {
          await when(() => this.rootStore.walletStore.hasBsvAccount);
        }

        await this.sendAuthorized();
        await sleep(100);

        this.rootStore._lock--;
      },
      { fireImmediately: true }
    );
  };

  private ensureHasAccess = (requestId: RequestId, from: string): boolean => {
    const {
      walletStore: { hasBsvAccount, hasWallet },
    } = this.rootStore;

    if (!hasBsvAccount || !hasWallet) {
      this.sendMessage(requestId, WalletEvent.LoginRequired, from);
      return false;
    }

    return true;
  };

  private handleMessage = async ({
    data: { event, payload, requestId = null },
  }: SelfMessageEvent) => {
    if (!event) {
      return;
    }

    console.log("handleMessage in iframe:", event, payload);

    if (!requestId) {
      console.log("requestId is not defined #handleMessage #frameService");
    }

    try {
      if (event === ClientEvent.AppId) {
        await tryApplyPacket(this.rootStore, `${payload.packet}`);
      }

      if (!this.ensureHasAccess(requestId, "handleMessage")) {
        console.log(
          "Has no access. #handleMessage #frameService",
          event,
          payload,
          requestId
        );
        return;
      }

      if (event === ClientEvent.AppId) {
        this.sendMessage(requestId, WalletEvent.Unknown, true);

        return;
      }

      switch (event) {
        case ClientEvent.Logout:
          const {
            rootStore: {
              userStore: { signOut },
            },
          } = this;

          await signOut();
          await sleep(500);

          this.sendMessage(requestId, WalletEvent.LogoutCompleted, null);
          break;

        case ClientEvent.SendTransaction:
          await this.sendTransaction(requestId, payload);
          break;

        case ClientEvent.Topup:
        case ClientEvent.ViewDeposit:
          history.push(routes.wallet.money.uri("deposit"));

          await sleep(100);
          this.openMe(requestId);

          break;

        case ClientEvent.ViewWallet:
          history.push(routes.wallet.path);

          await sleep(100);
          this.openMe(requestId);

          break;

        case ClientEvent.SubscribeBalanceChanges:
          reaction(
            () => this.rootStore.walletStore.listAssets,
            () => {
              this.sendBalances(null, WalletEvent.Balances);
            }
          );

          reaction(
            () => this.rootStore.walletStore.balancesChanged,
            () => {
              this.sendBalances(null, WalletEvent.Balances);
            },
            { fireImmediately: true }
          );

          reaction(
            () => this.rootStore.historyStore.pendingDeposit,
            () => {
              this.sendBalances(null, WalletEvent.PendingBalance);
            }
          );

          reaction(
            () => this.rootStore.walletStore.bountyBalance,
            () => {
              this.sendBalances(null, WalletEvent.BountyBalance);
            }
          );

          this.sendMessage(
            requestId,
            WalletEvent.SubscribedBalanceChanges,
            null
          );
          break;
      }
    } catch (error) {
      this.sendMessage(requestId, WalletEvent.Error, {
        message: (error as Error).message,
      });
    }
  };

  private sendTransaction = async (requestId: RequestId, payload: unknown) => {
    try {
      const sentPayload = await sendTransaction(this.rootStore, payload);

      this.sendMessage(requestId, WalletEvent.TransactionSent, sentPayload);
    } catch (error) {
      this.sendMessage(requestId, WalletEvent.Error, {
        message: unwrapError(error),
      });
    }
  };

  private sendMessage = (
    requestId: RequestId,
    event: WalletEvent,
    payload: unknown = {}
  ) => {
    if (!event) {
      throw new Error("sendMessage: Event must be defined");
    }

    const isUndefined = (value: unknown) => typeof value === "undefined";

    if (isUndefined(requestId)) {
      throw new Error("sendMessage: requestId must be defined");
    }

    if (isUndefined(payload)) {
      throw new Error("sendMessage: Payload must be defined");
    }

    // this check must happen after all params validated,
    // because it's vital to validate all params in order to prevent wrong usage of the method,
    // no matter if it's in frame or not
    if (!isInFrame()) return;

    const message = { requestId, event, payload };

    if (!envHelper.isProduction()) {
      console.log("sendMessage from iframe #frameService:", message);
    }

    window.parent.postMessage(message, iFrameMasterHost);
  };

  private sendBalances = (requestId: RequestId, event: WalletEvent) => {
    const message = buildAllBalancesMessage(this.rootStore);

    this.sendMessage(requestId, event, message);
  };
}

export const frameService = new FrameService(rootStore);
