import {
  entries,
  flow,
  get,
  makeAutoObservable,
  reaction,
  remove,
  set,
  toJS,
  when,
} from "mobx";
import { RootStore } from "./rootStore";
import { TBridgeOperationDto, THistoryItem, THistoryResponse } from "src/types";
import { unwrapError } from "src/utils";

export type THistoryListItem = string;

export class HistoryStore {
  isReady: boolean = false;
  isLoading = false;
  loadCalledOnce = false;

  // _tokenId?: string;
  _totalCount: number = 0;
  offset: number = 0;
  data: { [idx: string]: THistoryItem } = {};
  refs: { [tx: string]: TBridgeOperationDto } = {};

  pendingDeposit?: TBridgeOperationDto;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    reaction(
      () => this.rootStore.userStore.IsLoggedIn,
      (isLoggedIn) => {
        if (isLoggedIn) return;

        this._totalCount = 0;
        this.offset = 0;
        this.loadCalledOnce = false;

        for (const key in this.data) {
          remove(this.data, key);
        }
      },
      { fireImmediately: true }
    );
  }

  get totalCount() {
    const { _totalCount, pendingDeposit } = this;

    if (pendingDeposit) return _totalCount + 1;

    return _totalCount;
  }

  get list(): THistoryListItem[] {
    const { data, pendingDeposit } = this;

    let result: THistoryListItem[] = entries(data).map(([idx, item]) => idx);

    if (pendingDeposit) {
      result = ["pending", ...result];
    }

    return result;
  }

  getItem = (idx: THistoryListItem): THistoryItem | TBridgeOperationDto => {
    if (idx !== "pending") return get(this.data, idx);

    return this.pendingDeposit!;
  };

  getRef = (txId: string): TBridgeOperationDto | undefined => {
    return get(this.refs, txId);
  };

  init = flow(function* (this: HistoryStore) {
    // await when(() => isHydrated(this));

    yield Promise.allSettled([
      when(() => this.rootStore.userStore.isReady),
      when(() => this.rootStore.walletStore.isReady),
      // when(() => this.rootStore.bridgeStore.isReady),
    ]);

    this.isReady = true;
  });

  depositStatusUpdate = (status: TBridgeOperationDto) => {
    if (status.IsDeposit) {
      this.pendingDeposit = status;
    } else {
      set(this.refs, status.BridgeTxId, status);
    }
  };

  reload = flow(function* (this: HistoryStore) {
    if (this.isLoading) return;

    const { loadPage, offset } = this;
    const take = 15;

    if (offset === 0) {
      yield loadPage();
      return;
    }

    this.isLoading = true;

    try {
      const { _loadBatch, data } = this;
      const currentState = toJS(data);

      let o = 0;
      let k = 0;
      let totalCount = 0;
      const { TxId: currenFirstTxId } = currentState["0"];
      const addItems: { idx: string; row: THistoryItem }[] = [];

      while (true) {
        const page: THistoryResponse = yield _loadBatch(o, take);
        if (!page) return;

        totalCount = page.TotalCount; // TODO Restart if totalCount change during loading
        let stop = false;

        for (let i = 0; i < page.History.length; i++) {
          const row = page.History[i];
          const idx = (o + i).toString();
          const existing = currentState[idx];

          if (!existing) {
            // no more rows loaded
            stop = true;
            break;
          } else {
            if (existing.TxId === row.TxId) {
              // nothing changed
              stop = true;
              break;
            }
            if (row.TxId === currenFirstTxId) {
              // diff found
              stop = true;
              break;
            }

            k++;
            addItems.push({ idx, row });
          }
        }

        if (page.History.length !== take || stop) break;
        o += take;
      }

      if (k > 0) {
        let i = 0;
        for (const { idx, row } of addItems) {
          i++;
          set(this.data, idx, row);

          if (row.BridgeOperation) {
            set(this.refs, row.TxId, row.BridgeOperation);
          }
        }

        for (const p in currentState) {
          i++;
          const row = currentState[p];

          set(this.data, (+p + k).toString(), currentState[p]);

          if (row.BridgeOperation) {
            set(this.refs, row.TxId, row.BridgeOperation);
          }
        }

        this._totalCount = totalCount;
        this.offset = i;
      }
    } catch (error) {
      console.error(unwrapError(error));
    } finally {
      this.isLoading = false;
    }
  });

  loadPage = flow(function* (this: HistoryStore) {
    const {
      isLoading,
      offset,
      _totalCount,
      rootStore: {
        walletStore: { hasBsvAccount },
      },
    } = this;

    if (isLoading || (offset > 0 && offset === _totalCount) || !hasBsvAccount)
      return;

    this.isLoading = true;

    try {
      const payload: THistoryResponse = yield this._loadBatch(offset, 15);

      if (payload) {
        let inserted = 0;

        for (const row of payload.History) {
          set(this.data, (offset + inserted).toString(), row);
          inserted++;
        }

        this._totalCount = payload.TotalCount;
        this.offset = offset + inserted;
      }

      this.loadCalledOnce = true;
    } catch (error) {
      console.error(unwrapError(error));
    } finally {
      this.isLoading = false;
    }
  });

  _loadBatch = flow(function* (
    this: HistoryStore,
    offset: number,
    take: number
  ) {
    const { BsvAccount, tokenIds } = this.rootStore.walletStore;

    const data: THistoryResponse =
      yield this.rootStore.connectionStore.getHistory({
        Desc: true,
        Skip: offset,
        Take: take,
        Address: BsvAccount.Address,
        TokenIds: tokenIds,
        SkipZeroBalance: true,
      });

    if (offset === 0) {
      this.pendingDeposit = data?.PendingDeposit ?? undefined;
    }

    return data;
  });
}
