import { flow, makeAutoObservable, reaction, when } from "mobx";
import {
  HttpTransportType,
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
  LogLevel,
} from "@microsoft/signalr";
import { MessagePackHubProtocol } from "@microsoft/signalr-protocol-msgpack";
import { consigliereHost } from "src/services";
import { RootStore } from "./rootStore";
import {
  TAddContactRequest,
  TBalanceRequest,
  TBalanceResponse,
  TBatchBroadcastInitRequest,
  TBatchBroadcastInitResponse,
  TBatchBroadcastSendRequest,
  TContact,
  TCreateBsvAccountRequest,
  TCreateBsvAccountResponse,
  TCreateTonTransactionPayloadRequest,
  TCreateTonTransactionPayloadResponse,
  TCreateWeb3AccountRequest,
  THistoryRequest,
  THistoryResponse,
  TOrderedPageRequest,
  TTransactionsResponse,
  TUpdateSettingRequest,
  TUtxoSetRequest,
  TUtxoSetResponse,
  TWeb3Account,
} from "src/types";
import { isString } from "lodash";
import { sleep } from "src/utils";

let ownLock = 0;

const keepAliveInterval = 5000;
const serverTimeout = keepAliveInterval * 2;
const invokeTimeout = serverTimeout * 2;

export class ConnectionStore {
  isReady: boolean = false;
  connected: boolean = false;

  _connection!: HubConnection;
  _connectionStatusAwaiter?: Promise<void>;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  init = async () => {
    if (this.isReady) return;

    const protocol = new MessagePackHubProtocol({
      forceFloat32: true,
    });

    this._connection = new HubConnectionBuilder()
      .withUrl(`${consigliereHost}/ws/app`, {
        accessTokenFactory: () => {
          const { AccessToken } = this.rootStore.userStore;

          if (!AccessToken) throw Error("Unauthorized");

          return AccessToken;
        },
        withCredentials: false,
        transport: HttpTransportType.WebSockets,
      })
      .withKeepAliveInterval(keepAliveInterval)
      .withServerTimeout(serverTimeout)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: () => Math.random() * 3000,
      })
      .configureLogging(LogLevel.Debug)
      .withHubProtocol(protocol)
      .build();

    this._atachHandlers();

    this._connection.onclose(this._onDisconnect);
    this._connection.onreconnecting(this._onDisconnect);

    document.addEventListener(
      "visibilitychange",
      async () => await this._ensureInValidState(true)
    );
    window.addEventListener(
      "focus",
      async () => await this._ensureInValidState(true)
    );

    reaction(
      () => this.rootStore.userStore.IsLoggedIn,
      async () => await this._ensureInValidState(false),
      { fireImmediately: false }
    );

    reaction(
      () => this.connected,
      async (connected) => {
        if (connected) {
          await this._ensureInValidState(true);
          await this.rootStore.historyStore.reload();
        }
      }
    );

    await this._ensureInValidState(false);

    this.isReady = true;
  };

  _atachHandlers = () => {
    const {
      clientStore,
      compensationsStore,
      historyStore,
      ratesStore,
      walletStore,
    } = this.rootStore;

    this._subscribe("OnClientChanged", clientStore.onClientChanged);
    this._subscribe("OnSettingChanged", clientStore.onSettingChanged);
    this._subscribe("OnBsvAccountChanged", walletStore.onBsvAccountChanged);
    this._subscribe("OnWeb3AccountChanged", walletStore.onWeb3AccountChanged);
    this._subscribe("OnBalanceChanged", async (b) => {
      await walletStore.onBalanceChange(b);
    });
    this._subscribe("OnDepositStatusChanged", historyStore.depositStatusUpdate);
    this._subscribe("OnFeeChanged", compensationsStore.setOperationsFees);
    this._subscribe("OnRateChanged", ratesStore.onRateChaged);
    this._subscribe(
      "OnCompensationChanged",
      compensationsStore.setCompensation
    );
    this._subscribe("OnSuccessfulConnection", this._onSuccessfulConnection);
    this._subscribe("OnTransactionFound", async () => {
      await walletStore.onBalanceChange();
    });
  };

  _ensureInValidState = this.rootStore.singleCall(
    "ensureInValidState",
    flow(function* (rootStore: RootStore, ensureAlive: boolean) {
      if (document.visibilityState !== "visible") return;

      const {
        connectionStore: $this,
        userStore: { IsLoggedOut },
      } = rootStore;

      let resolver: () => void = () => {};
      let locked = false;

      $this._connectionStatusAwaiter = new Promise((resolve) => {
        resolver = () => {
          resolve();
          $this._connectionStatusAwaiter = undefined;
        };
      });

      if (!IsLoggedOut && ensureAlive) {
        yield sleep(keepAliveInterval);
      }

      try {
        if (IsLoggedOut) {
          $this.connected = false;
          // if ($this._connection.state !== HubConnectionState.Disconnected) {
          yield $this._connection.stop();
          // }

          return;
        }

        if (
          $this._connection.state === HubConnectionState.Disconnected ||
          $this._connection.state === HubConnectionState.Disconnecting
        ) {
          $this.connected = false;

          $this.lock();
          locked = true;

          yield $this._connection.start();
          yield when(() => $this.connected, {
            // timeout: invokeTimeout * 2,
          });
        }
      } catch (error) {
        console.error(`Fiorin connection state error: ${error}`);

        $this.rootStore.notificationStore.addNotification("Network error");
      } finally {
        if (locked) {
          $this.unlock();
        }
        resolver();
      }
    })
  );

  _subscribe = (methodName: string, method: (...args: any[]) => void) => {
    this._connection.off(methodName, method);
    this._connection.on(methodName, method);
  };

  _invoke = async <T = any>(methodName: string, ...args: any[]): Promise<T> => {
    if (this._connectionStatusAwaiter) {
      const timeout = async () => {
        await sleep(invokeTimeout);

        return true;
      };
      const networkIssue = await Promise.any([
        this._connectionStatusAwaiter,
        timeout(),
      ]);

      if (networkIssue === true) {
        console.log("Fiorin operation rejected, due to connection issue");

        this.rootStore.notificationStore.addNotification("Wallet error");

        throw Error();
      }
    }

    if (!this.connected) {
      this.rootStore.notificationStore.addNotification(
        "Wallet is connecting to server"
      );

      throw Error();
    }

    if (this._connection.state !== HubConnectionState.Connected) {
      this.rootStore.notificationStore.addNotification("Wallet is offline");

      throw Error();
    }

    try {
      const result = await this._connection.invoke<T>(methodName, ...args);

      return result;
    } catch (error) {
      console.error(
        "Fiorin api operation error",
        isString(error) ? error : `${error}`
      );

      this.rootStore.notificationStore.addNotification("Wallet error");

      throw Error();
    }
  };

  _onDisconnect = () => {
    this.connected = false;
  };

  _onSuccessfulConnection = () => {
    this.connected = true;
    this.unlock();
  };

  disconnect = async () => {
    this.connected = false;
    try {
      await this._connection.stop();
    } catch (error) {
      console.error(`Fiorin disconnecting error: ${error}`);
    }
  };

  lock = () => {
    if (ownLock === 0) {
      ownLock = 1;
      this.rootStore._lock += 1;
    }
  };

  unlock = () => {
    if (ownLock === 1) {
      ownLock = 0;
      this.rootStore._lock -= 1;
    }
  };

  // address api

  getBalance = (request: TBalanceRequest) =>
    this._invoke<TBalanceResponse>("GetBalance", request);

  getHistory = (request: THistoryRequest) =>
    this._invoke<THistoryResponse>("GetHistory", request);

  getUtxos = (request: TUtxoSetRequest) =>
    this._invoke<TUtxoSetResponse>("GetUtxoSet", request);

  // address api

  // transaction api

  getTransactions = (ids: string[]): Promise<TTransactionsResponse> =>
    this._invoke<TTransactionsResponse>("GetTransactions", ids);

  broadcast = (transaction: string): Promise<boolean> =>
    this._invoke<boolean>("Broadcast", transaction);

  stasBatchInit = (
    request: TBatchBroadcastInitRequest
  ): Promise<TBatchBroadcastInitResponse> =>
    this._invoke<TBatchBroadcastInitResponse>("StasBatchInit", request);

  stasBatchBroadcast = (
    request: TBatchBroadcastSendRequest
  ): Promise<boolean> => this._invoke<boolean>("StasBatchBroadcast", request);

  // transaction api

  // wallet api

  createBsvAccounts = (
    request: TCreateBsvAccountRequest
  ): Promise<TCreateBsvAccountResponse> =>
    this._invoke<TCreateBsvAccountResponse>("CreateBsvAccounts", request);

  createWeb3Account = (
    request: TCreateWeb3AccountRequest
  ): Promise<TWeb3Account> =>
    this._invoke<TWeb3Account>("CreateWeb3Account", request);

  // wallet api

  // client api

  listContacts = (request: TOrderedPageRequest): Promise<TContact[]> =>
    this._invoke<TContact[]>("ListContacts", request);

  addContact = (request: TAddContactRequest): Promise<void> =>
    this._invoke<void>("AddContact", request);

  updateSetting = (request: TUpdateSettingRequest): Promise<void> =>
    this._invoke<void>("UpdateSetting", request);

  // client api

  // ton bridge api

  createTonTransactionPayload = (
    request: TCreateTonTransactionPayloadRequest
  ): Promise<TCreateTonTransactionPayloadResponse> =>
    this._invoke<TCreateTonTransactionPayloadResponse>(
      "CreateTonTransactionPayload",
      request
    );

  // ton bridge api
}
