import {
  identityClient,
  TApiResponse,
  TIdentityResponse,
  TSingInRequest,
  TSingUpRequest,
} from "src/clients";
import { flow, makeAutoObservable, when } from "mobx";
import { isHydrated, makePersistable } from "mobx-persist-store";

import { RootStore } from "./rootStore";
import { custodialWalletSetting } from "src/types.enums";
import { TUser } from "src/types";

export class UserStore {
  user?: TUser;
  _accessToken?: string;

  isReady: boolean = false;

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });

    makePersistable(this, {
      name: "UserStore",
      properties: ["_accessToken"],
    });
  }

  get IsLoggedIn() {
    return this.isReady && Boolean(this._accessToken);
  }

  get IsLoggedOut() {
    return !this.IsLoggedIn;
  }

  get User() {
    return this.user!;
  }

  get AccessToken() {
    return this._accessToken!;
  }

  init = flow(function* (this: UserStore) {
    yield when(() => isHydrated(this));
    yield this._getUser();

    this.isReady = true;
  });

  singIn = this.rootStore.blockingCall(
    flow(function* (rootStore: RootStore, request: TSingInRequest) {
      const response = yield identityClient.signIn(request);
      const error = yield rootStore.userStore._handleApiResponse(response);

      if (error) return error;

      yield when(() => rootStore.connectionStore.connected);

      const { walletStore } = rootStore;

      if (rootStore.clientStore.IsCustodial && !walletStore.hasWallet) {
        console.warn("Signed in client is custodial and has no wallet");

        yield walletStore.createPkAndSave();
      }
    })
  );

  singUp = this.rootStore.blockingCall(
    flow(function* (
      rootStore: RootStore,
      request: TSingUpRequest,
      createPk: boolean
    ) {
      const $this = rootStore.userStore;
      const response = yield identityClient.signUp(request);
      const error = yield $this._handleApiResponse(response);

      if (error) return error;

      if (!error && createPk) {
        yield when(() => rootStore.connectionStore.connected);

        yield rootStore.connectionStore.updateSetting({
          Type: custodialWalletSetting,
          Value: "true",
        });
        yield rootStore.walletStore.createPkAndSave();
      }
    })
  );

  signOut = () => {
    this._accessToken = undefined;
    this.user = undefined;
    this.rootStore.connectionStore.disconnect();
  };

  _getUser = flow(function* (this: UserStore) {
    let result = false;

    if (this._accessToken) {
      try {
        const { payload }: TApiResponse<TUser> = yield identityClient.getUser(
          this.AccessToken
        );

        if (payload) {
          this.user = payload;
          this._accessToken = payload.token;

          result = true;
        } else {
          this._accessToken = undefined;
          this.user = undefined;
        }
      } catch (error) {
        console.error(error);
      }
    }

    return result;
  });

  _setAccessToken = flow<boolean, [accessToken: string]>(function* (
    this: UserStore,
    accessToken: string
  ) {
    this._accessToken = accessToken;

    return yield this._getUser();
  });

  _handleApiResponse = flow<
    TMaybeString,
    [response: TApiResponse<TIdentityResponse>]
  >(function* (
    this: UserStore,
    { payload, error }: TApiResponse<TIdentityResponse>
  ) {
    if (payload?.token) {
      this._accessToken = payload.token;

      yield this._getUser();

      return null;
    }
    const errorsObject = JSON.parse(error);

    if (errorsObject.errors?.length) {
      const errors = errorsObject.errors;

      if (errors[0].description) return errors[0].description;
      else return errors[0];
    }

    if (payload?.errors.length) return payload.errors[0];

    return error;
  });
}
